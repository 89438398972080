import { HistoryStatuesType } from '../../helpers/types';
import {
  GET_HISTORY_STATUSES_BEGIN,
  GET_NETWORK_BEGIN,
  GET_SUBDOMAINS_BEGIN,
} from '../constant';

export const getHistoryStatusesAction = (payload: HistoryStatuesType) => ({
  type: GET_HISTORY_STATUSES_BEGIN,
  payload,
});

export const getSubDomainsAction = (payload: number | null) => ({
  type: GET_SUBDOMAINS_BEGIN,
  payload,
});

export const getNetworkAction = (payload: number | null) => ({
  type: GET_NETWORK_BEGIN,
  payload,
});
