import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Box, Pagination, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  DatePickers,
  Dropdown,
  GradientButton,
  Header,
  Input,
  SelectOption,
  Sidebar,
} from '../../components';
import {
  LayoutContainer,
  MainContainer,
} from '../../components/Layout/Style/Layout.style';
import TakedownTable from './TakedownTable';
import { PaginationStyle } from '../ThreadsDetails/Style/SingleThreat.style';
import {
  defaultEndDateTakedown,
  defaultStartDateTakedown,
  exportToCSV,
  getLabelsAndIds,
} from '../../utils';
import {
  getStatusTakedown,
  getTakeDownListAction,
} from '../../store/takedown/action';
import { CompaniesType } from '../../helpers/types';
import ManualTakedown from './ManualTakedown';

const TakedownList = () => {
  const [searchThreatName, setSearchThreatName] = useState<string>('');
  const [searchTargetDomain, setSearchTargetDomain] = useState<string>('');
  const [takeDownCurrentPage, setTakeDownCurrentPage] = useState<number>(1);
  const [startDateFilterValues, setStartDateFilterValues] = useState<string>(
    defaultStartDateTakedown,
  );
  const [endDateFilterValues, setEndDateFilterValues] = useState<string>(
    defaultEndDateTakedown,
  );
  const [companyFilterValues, setCompanyFilterValues] = useState<any>([]);
  const [selectedStatusTakedown, setSelectedStatusTakedown] =
    useState<string>('');
  const [openManualTakedown, setManualTakedown] = useState<boolean>(false);

  const { takedownList, getStatustakeDown } = useSelector(
    (state: any) => state.takedown,
  );
  const { getCompany } = useSelector((state: any) => state.company);

  const count = Math.ceil(takedownList?.data?.total / 100);
  const dispatch = useDispatch();
  const selectedCompanyLabels = getLabelsAndIds(companyFilterValues, 'id');

  const fetchTakeDownList = () => {
    const params: any = {
      since: startDateFilterValues,
      until: endDateFilterValues,
      threat_domain: searchThreatName,
      target_domain: searchTargetDomain,
      status_names: selectedStatusTakedown ? selectedStatusTakedown : null,
      company_id:
        selectedCompanyLabels.length > 0 ? selectedCompanyLabels : undefined,
      page: takeDownCurrentPage,
      size: 100,
    };
    dispatch(getTakeDownListAction(params));
  };

  useEffect(() => {
    fetchTakeDownList();
  }, [takeDownCurrentPage]);

  useEffect(() => {
    dispatch(getStatusTakedown());
  }, []);

  const companiesData = useCallback(
    getCompany?.data?.map((company: CompaniesType) => ({
      label: company.Name,
      id: company.Id,
    })),
    [getCompany?.data],
  );

  const onChangeCompany = (e: React.SyntheticEvent, value: string[]) => {
    setCompanyFilterValues(value);
  };

  const handleChangeStatusTakedown = (e: any) => {
    setSelectedStatusTakedown(e.target.value);
  };

  const handleClearFilter = () => {
    setSearchThreatName('');
    setSearchTargetDomain('');
    setCompanyFilterValues([]);
    setSelectedStatusTakedown('');
    setStartDateFilterValues(defaultStartDateTakedown);
    setEndDateFilterValues(defaultEndDateTakedown);
    setTakeDownCurrentPage(1);
    dispatch(
      getTakeDownListAction({
        threat_domain: '',
        target_domain: '',
        since: startDateFilterValues,
        until: endDateFilterValues,
        page: 1,
        size: 100,
      }),
    );
  };

  const handleTakedownSearch = () => {
    setTakeDownCurrentPage(1);
    fetchTakeDownList();
  };

  const handleChangeThreatName = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchThreatName(e.target.value);
  };

  const handleChangeTargetDomain = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTargetDomain(e.target.value);
  };

  const onChangeDate = (value: any) => {
    if (value) {
      const formattedStartDate = dayjs(value[0]).format('YYYY-MM-DD HH:mm:ss');
      const formattedEndDate = dayjs(value[1]).format('YYYY-MM-DD HH:mm:ss');
      setStartDateFilterValues(formattedStartDate);
      setEndDateFilterValues(formattedEndDate);
    } else {
      setStartDateFilterValues('');
      setEndDateFilterValues('');
    }
  };

  const handleChangeTakeDownPage = (event: any, value: number) => {
    setTakeDownCurrentPage(value);
  };

  const handleExport = () => {
    exportToCSV(takedownList?.data?.items, 'takedown_list.csv');
  };

  const statusTakedownData = useCallback(
    getStatustakeDown?.data?.map((item: any) => ({
      label: item.name,
      value: item.name,
    })),
    [getStatustakeDown?.data],
  );

  const handleAddManulTakedownModal = () => {
    setManualTakedown(true);
  };

  return (
    <>
      <Box sx={{ padding: '24px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" sx={{ fontWeight: 600, color: 'black' }}>
            Takedowns
          </Typography>
          <Box>
            <GradientButton
              variant="contained"
              onClick={handleAddManulTakedownModal}>
              Enrich Takedown
            </GradientButton>
            <Button variant="outlined" onClick={handleExport} sx={{ ml: 1.5 }}>
              Export
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', mt: 2, mb: 3 }}>
          <Input
            size="small"
            placeholder="Threat name or URL"
            onChange={handleChangeThreatName}
            value={searchThreatName}
            sx={{ '& .MuiOutlinedInput-root': { borderRadius: 1 }, mr: 2 }}
          />
          <Input
            size="small"
            placeholder="Target Domain"
            onChange={handleChangeTargetDomain}
            value={searchTargetDomain}
            sx={{ '& .MuiOutlinedInput-root': { borderRadius: 1 }, mr: 2 }}
          />
          <Box sx={{ width: '45%', display: 'flex', gap: 2 }}>
            <DatePickers
              size="small"
              onChange={onChangeDate}
              defaultStartDate={defaultStartDateTakedown}
              defaultEndDate={defaultEndDateTakedown}
            />
            <Dropdown
              items={companiesData}
              size="small"
              placeholder="Company"
              label="Company"
              onChange={onChangeCompany}
              selectedValues={companyFilterValues}
              multiple={true}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex', mb: 2.5 }}>
          <Box sx={{ width: '20%' }}>
            <SelectOption
              label="status"
              size="small"
              onChange={handleChangeStatusTakedown}
              value={selectedStatusTakedown}
              options={statusTakedownData}
              width="100%"
            />
          </Box>
          <GradientButton
            variant="contained"
            sx={{ mx: 2 }}
            onClick={handleTakedownSearch}>
            <SearchIcon />
            <span style={{ paddingLeft: '2px' }}>Search</span>
          </GradientButton>
          <Button onClick={handleClearFilter} variant="outlined">
            Reset
          </Button>
        </Box>
        <TakedownTable
          takeDownCurrentPage={takeDownCurrentPage}
          startDateFilterValues={startDateFilterValues}
          endDateFilterValues={endDateFilterValues}
        />
        {takedownList?.data?.total > 100 && !takedownList?.loading && (
          <PaginationStyle>
            <Pagination
              count={count}
              page={takeDownCurrentPage}
              onChange={handleChangeTakeDownPage}
              color="primary"
            />
          </PaginationStyle>
        )}
      </Box>
      <ManualTakedown
        open={openManualTakedown}
        handleClose={() => setManualTakedown(false)}
      />
    </>
  );
};

export default TakedownList;
