import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, SelectOption } from '..';
import { formatedDate, threatsStatus } from '../../utils';
import { ThreatDetails } from '../ThreatCard/ThreatCard.style';

const ThreatTable = ({
  threats,
  handleChangeSelectedThreats,
  isThreatSelected,
}: any) => {
  const { getCompany } = useSelector((state: any) => state.company);
  const { getNetwork } = useSelector((state: any) => state.singleThreat);

  const whoisData = getNetwork?.data?.items?.find(
    (item: any) => item.type === 'whois',
  );
  const domainInfo = whoisData?.data?.domain_information;

  const findCompanyNameById = (id: number) => {
    const company = getCompany?.data?.find((company: any) => company.Id === id);
    return company ? company.Name : 'Unknown';
  };

  const handleChangeDomainStatus = (domainId: number, newStatus: string) => {
    console.log(`Domain ID: ${domainId}, New Status: ${newStatus}`);
  };

  return (
    <TableContainer component={Paper} sx={{ border: '1px solid #e2e2e2' }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Threat Name</TableCell>
            <TableCell>Target Domain</TableCell>
            <TableCell>Company</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>CTI Score</TableCell>
            <TableCell>Registrar</TableCell>
            <TableCell>Registration</TableCell>
            <TableCell>Expiration</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {threats?.items?.map((items: any) => {
            const [selectedStatus, setSelectedStatus] = useState(
              items.identification_status?.value,
            );
            return (
              <TableRow key={items.threat_id}>
                <TableCell>
                  <Checkbox
                    checked={isThreatSelected({
                      threatId: items.threat_id,
                      companyId: items.company_id,
                      domainId: items.protected_asset_id,
                    })}
                    onChange={() =>
                      handleChangeSelectedThreats({
                        domain: items.protected_asset_name,
                        threat: items.threat_name,
                        threatId: items.threat_id,
                        companyId: items.company_id,
                        domainId: items.protected_asset_id,
                        status: items.identification_status.value,
                      })
                    }
                    sx={{ paddingLeft: '0' }}
                  />
                  <Link
                    to={`/threats/${encodeURIComponent(
                      items?.threat_name,
                    )}/${encodeURIComponent(
                      items?.protected_asset_name,
                    )}/${encodeURIComponent(
                      items?.company_id,
                    )}?protected_id=${encodeURIComponent(
                      items?.protected_asset_id,
                    )}&threat_id=${encodeURIComponent(items?.threat_id)}`}
                    target="_blank"
                    style={ThreatDetails}>
                    {items?.threat_name?.length > 20
                      ? items.threat_name.substring(0, 20) + '...'
                      : items.threat_name}
                  </Link>
                </TableCell>
                <TableCell>{items?.protected_asset_name}</TableCell>
                <TableCell>{findCompanyNameById(items?.company_id)}</TableCell>
                <TableCell>
                  <SelectOption
                    size="small"
                    width="120px"
                    value={selectedStatus}
                    options={threatsStatus.map(status => ({
                      label: status,
                      value: status,
                    }))}
                    onChange={(e: any) => {
                      const newStatus = e.target.value;
                      setSelectedStatus(newStatus);
                      handleChangeDomainStatus(
                        items.protected_asset_id,
                        newStatus,
                      );
                    }}
                  />
                </TableCell>
                <TableCell>{items?.score?.value || '-'}</TableCell>
                <TableCell>{domainInfo?.registrar || '-'}</TableCell>
                <TableCell>
                  {formatedDate(domainInfo?.registration_date)}
                </TableCell>
                <TableCell>
                  {formatedDate(domainInfo?.expiration_date)}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ background: '#34a6ed' }}>
                    Generate PDF
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ThreatTable;
