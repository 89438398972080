import {
  GET_HISTORY_STATUSES_BEGIN,
  GET_HISTORY_STATUSES_FAILURE,
  GET_HISTORY_STATUSES_SUCCESS,
  GET_NETWORK_BEGIN,
  GET_NETWORK_FAILURE,
  GET_NETWORK_SUCCESS,
  GET_SUBDOMAINS_BEGIN,
  GET_SUBDOMAINS_FAILURE,
  GET_SUBDOMAINS_SUCCESS,
} from './../constant';

const initialState = {
  subDomains: {
    loading: false,
    data: [],
    error: null,
  },
  getNetwork: {
    loading: false,
    data: [],
    error: null,
  },
  historyStatuses: {
    loading: false,
    data: [],
    error: null,
  },
};

export const singleThreatReducer = (
  state = initialState,
  action: { type: string; payload: string },
) => {
  switch (action.type) {
    case GET_SUBDOMAINS_BEGIN:
      return {
        ...state,
        subDomains: {
          ...state.subDomains,
          loading: true,
        },
      };
    case GET_SUBDOMAINS_SUCCESS:
      return {
        ...state,
        subDomains: {
          ...state.subDomains,
          loading: false,
          data: action.payload,
        },
      };
    case GET_SUBDOMAINS_FAILURE:
      return {
        ...state,
        subDomains: {
          ...state.subDomains,
          loading: false,
          error: action.payload,
        },
      };
    case GET_NETWORK_BEGIN:
      return {
        ...state,
        getNetwork: {
          ...state.getNetwork,
          loading: true,
        },
      };
    case GET_NETWORK_SUCCESS:
      return {
        ...state,
        getNetwork: {
          ...state.getNetwork,
          loading: false,
          data: action.payload,
        },
      };
    case GET_NETWORK_FAILURE:
      return {
        ...state,
        getNetwork: {
          ...state.getNetwork,
          loading: false,
          error: action.payload,
        },
      };
    case GET_HISTORY_STATUSES_BEGIN:
      return {
        ...state,
        historyStatuses: {
          ...state.historyStatuses,
          loading: true,
        },
      };
    case GET_HISTORY_STATUSES_SUCCESS:
      return {
        ...state,
        historyStatuses: {
          ...state.historyStatuses,
          loading: false,
          data: action.payload,
        },
      };
    case GET_HISTORY_STATUSES_FAILURE:
      return {
        ...state,
        historyStatuses: {
          ...state.historyStatuses,
          loading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
