import { call, put } from 'redux-saga/effects';
import {
  GET_HISTORY_STATUSES_FAILURE,
  GET_HISTORY_STATUSES_SUCCESS,
  GET_NETWORK_FAILURE,
  GET_NETWORK_SUCCESS,
  GET_SUBDOMAINS_FAILURE,
  GET_SUBDOMAINS_SUCCESS,
} from '../constant';
import { getHistoryStatuesApi, getSubDomainsApi, getNetworkApi } from '.';

export function* getSubDomainsSaga(action: any): any {
  try {
    const response = yield call(getSubDomainsApi, action.payload);
    yield put({
      type: GET_SUBDOMAINS_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_SUBDOMAINS_FAILURE,
      payload: error.message,
    });
  }
}

export function* getNetworkSaga(action: any): any {
  try {
    const response = yield call(getNetworkApi, action.payload);
    yield put({
      type: GET_NETWORK_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_NETWORK_FAILURE,
      payload: error.message,
    });
  }
}

export function* getHistoryStatuesSaga(action: any): any {
  try {
    const response = yield call(getHistoryStatuesApi, action.payload);
    yield put({
      type: GET_HISTORY_STATUSES_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_HISTORY_STATUSES_FAILURE,
      payload: error.message,
    });
  }
}
