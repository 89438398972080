import { Box, styled, TableCell, Typography } from '@mui/material';

export const EnrichWrapper = styled(Box)(() => ({
  backgroundColor: '#f6f7f87f',
  padding: '24px',
  height: '100vh',
}));

export const EnrichContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '20px',
}));

export const EnrichLabel = {
  fontWeight: 500,
  color: 'black',
  fontSize: '15px',
};

export const FormBox = styled(Box)({
  width: '680px',
  padding: '30px',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#fff',
});

export const FormTitle = styled(Typography)({
  fontWeight: 600,
  marginBottom: '16px',
  textAlign: 'center',
});

export const FormSubtitle = styled(Typography)({
  marginBottom: '24px',
  textAlign: 'center',
  color: '#666',
});

export const ManualTakedownWrapper = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '480px',
  bgcolor: 'background.paper',
  border: '0',
  boxShadow: '0 .5rem 1rem rgba(0, 0, 0, .1)',
  borderRadius: '.475rem',
};

export const ManualTakedownTitle = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '24px 24px 0 24px',
}));

export const ManualTakedownId = styled(Box)(() => ({
  padding: '0 24px',
  gap: '6px',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}));

export const ButtonWrapper = styled(Box)(() => ({
  justifyContent: 'center',
  gap: '14px',
  display: 'flex',
  marginBottom: '24px',
}));

export const CustomTableCell = styled(TableCell)(() => ({
  background: 'rgb(249, 250, 252)',
  color: 'rgba(58, 53, 65, 0.87)',
  fontWeight: 'bold',
}));
