import React from 'react';
import { Box } from '@mui/material';
import { DateRangePicker } from 'rsuite';
import { DatePickerProps } from '../../helpers/types';
import 'rsuite/dist/rsuite.css';

const DatePickers = ({
  onChange,
  defaultStartDate,
  defaultEndDate,
}: DatePickerProps) => {
  const startDate: any = defaultStartDate ? new Date(defaultStartDate) : null;
  const endDate: any = defaultEndDate ? new Date(defaultEndDate) : null;

  return (
    <Box sx={{ width: '100%' }}>
      <DateRangePicker
        placeholder="YYYY-MM-DD"
        onChange={onChange}
        defaultValue={[startDate, endDate]}
        format="yyyy-MM-dd"
      />
    </Box>
  );
};

export default DatePickers;
