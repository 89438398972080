import React from 'react';
import {
  Box,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
  CancelButton,
  CompleteTakedowntDialog,
  CusttomButton,
} from './Style/SingleThreat.style';
import { Input, Loader, SelectOption } from '../../components';
import { CompleteTakedownModalType } from '../../helpers/types';

const CompleteTakedownModal = ({
  handleClose,
  openCompleteTakedown,
  handleCompleteTakedown,
  setTakedownMessage,
  takedownMessage,
  handleChangeStatusTakedown,
  statusTakedownData,
  selectedStatusTakedown,
}: CompleteTakedownModalType) => {
  const { completeTakedown } = useSelector((state: any) => state.takedown);

  const showDescriptionInput = [
    'final_escalation',
    '3rd_party_unresponsive',
    'completed',
  ].includes(selectedStatusTakedown || '');

  return (
    <CompleteTakedowntDialog open={openCompleteTakedown} onClose={handleClose}>
      <DialogContent>
        <DialogContentText>
          Completing a takedown will change the status to takedown success and
          notify customer_name.
          <Box sx={{ my: 1.5 }}>Do you want to proceed ?</Box>
        </DialogContentText>
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Typography gutterBottom sx={{ color: 'black', width: '30%' }}>
            Status
          </Typography>
          <SelectOption
            label="status"
            size="small"
            onChange={handleChangeStatusTakedown}
            value={selectedStatusTakedown}
            options={statusTakedownData}
            width="100%"
          />
        </Box>
        {showDescriptionInput && (
          <Box sx={{ display: 'flex', mt: 2 }}>
            <Typography gutterBottom sx={{ color: 'black', width: '30%' }}>
              Description
            </Typography>
            <Input
              placeholder="Add a message with more details about the takedown completion."
              type="text"
              size="small"
              variant="outlined"
              sx={{ width: '100%' }}
              value={takedownMessage}
              onChange={e => setTakedownMessage(e.target.value)}
            />
          </Box>
        )}
        <DialogActions>
          <CusttomButton onClick={() => handleCompleteTakedown()}>
            {completeTakedown.loading ? (
              <Loader size={22} />
            ) : (
              'Yes, Complete Takedown'
            )}
          </CusttomButton>
          <CancelButton onClick={handleClose}>Cancel</CancelButton>
        </DialogActions>
      </DialogContent>
    </CompleteTakedowntDialog>
  );
};

export default CompleteTakedownModal;
