import { call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  CHANGE_STATUSLABEL_FAILURE,
  CHANGE_STATUSLABEL_SUCCESS,
  CREATE_THREAT_FAILURE,
  CREATE_THREAT_SUCCESS,
  GET_DOMAINS_LIST_FAILURE,
  GET_DOMAINS_LIST_SUCCESS,
  GET_STATUS_FAILURE,
  GET_STATUS_SUCCESS,
  GET_THREAT_BEGIN,
  GET_THREAT_DETAIL_FAILURE,
  GET_THREAT_DETAIL_SUCCESS,
  GET_THREAT_FAILURE,
  GET_THREAT_SUCCESS,
} from '../constant';
import {
  changeStatusLabelApi,
  createThreatApi,
  getDomainsListApi,
  getStatusApi,
  getThreatApi,
  getThreatDetailApi,
} from '.';
import { EVENT_NAME, trackAnalytic } from '../../utils/analytic';

export function* getThreatSaga(action: any): any {
  try {
    const response = yield call(getThreatApi, action.payload);
    yield put({
      type: GET_THREAT_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    toast.error(
      error.response.data.detail[0].msg || error.response.data.detail,
    );
    yield put({
      type: GET_THREAT_FAILURE,
      payload: error.response.data.detail[0].msg,
    });
  }
}

export function* getThreatDetailSaga(action: any): any {
  try {
    const response = yield call(getThreatDetailApi, action.payload);
    yield put({
      type: GET_THREAT_DETAIL_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_THREAT_DETAIL_FAILURE,
      payload: error.response.data.detail[0].msg,
    });
    toast.error(error.response.data.detail[0].msg);
  }
}

export function* changeStatusLabel(action: any): any {
  try {
    const response = yield call(changeStatusLabelApi, action.payload);
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: CHANGE_STATUSLABEL_SUCCESS,
        payload: action.payload,
      });
      const state = yield select();
      const { DomainId, DomainName, ThreatId, ThreatName } = response.data;
      const analyticData = {
        domain: {
          id: DomainId,
          name: DomainName,
        },
        threat: {
          id: ThreatId,
          name: ThreatName,
        },
        currentStatus: action.payload.currentStatus,
        updatedStatus: action.payload.status,
        ...state.analytic,
      };
      yield call(trackAnalytic, EVENT_NAME.CHANGE_THREAT_STATUS, analyticData);
    }
  } catch (error: any) {
    yield put({
      type: CHANGE_STATUSLABEL_FAILURE,
      payload: action.payload,
      error: error.response.data.error || 'Something want wrong',
    });
  }
}

export function* createThreatSaga(action: any): any {
  try {
    const response = yield call(createThreatApi, action.payload);
    toast.success('Threat created successfully');
    yield put({
      type: CREATE_THREAT_SUCCESS,
      payload: response.data,
    });
    const resGetThreatApi = yield call(getThreatApi, {
      from: action.payload.from,
      until: action.payload.until,
      threat_name: '',
      status: 'confirmed',
      sort: 'updated_at',
      order: 'DESCENDING',
      page: 1,
      size: 16,
    });
    yield put({
      type: GET_THREAT_SUCCESS,
      payload: resGetThreatApi.data,
    });
  } catch (error: any) {
    yield put({
      type: CREATE_THREAT_FAILURE,
      payload: error.response.data.detail,
    });
    toast.error(
      error.response.data.detail[0].msg ||
        error.response.data.detail ||
        'Something want wrong',
    );
  }
}

export function* getDomainsListSaga(action: any): any {
  try {
    const response = yield call(getDomainsListApi, action.payload);
    yield put({
      type: GET_DOMAINS_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_DOMAINS_LIST_FAILURE,
      payload: error.message,
    });
  }
}

export function* getStatusSaga(action: any): any {
  try {
    const response = yield call(getStatusApi, action.payload);
    yield put({
      type: GET_STATUS_SUCCESS,
      payload: response.data,
    });
  } catch (error: any) {
    yield put({
      type: GET_STATUS_FAILURE,
      payload: error.response.data.message,
    });
  }
}
