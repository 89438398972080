import { HistoryStatuesType } from '../../helpers/types';
import { apiClient } from '../../services/apiClient';

export const getSubDomainsApi = async (DomainId: number) => {
  const config = {
    method: 'GET',
    url: `domain/subdomains?d=${DomainId}`,
  };
  const res = await apiClient(config);
  return res;
};

export const getNetworkApi = async (asset_id: number) => {
  const config = {
    method: 'GET',
    url: `v2/assets/${asset_id}/network`,
  };
  const res = await apiClient(config);
  return res;
};

export const getHistoryStatuesApi = async (data: HistoryStatuesType) => {
  const config = {
    method: 'GET',
    url: `threat/statuses?d=${data.d}&t=${data.t}`,
  };
  const res = await apiClient(config);
  return res;
};
